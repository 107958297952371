import React, { useState } from "react"
import { Typography, Grid, CardMedia } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins"
import Layout from "@components/Layout/Layout"
import Button from "@components/Buttons/Button.jsx"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import errImg from "@static/images/404.svg"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "30px",
    lineHeight: "36px",
    fontWeight: "500",
    color: palette.text.dark,
    textAlign: "center",
    [breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "21px",
    }
  },
  text_padding: {
    paddingBottom: "100px",
    paddingTop: "120px",
    [breakpoints.down("md")]: {
      paddingTop: "70px",
      paddingBottom: "55px",
    }
  },
  button: {
    marginTop: "100px",
    marginBottom: "150px",
    [breakpoints.down("md")]: {
      marginTop: "75px",
      marginBottom: "50px",
    }
  }
})

const Page404 = () => {
  const {
    text,
    text_padding,
    button,
  } = makeComponentStyles(styles)

  const Intl = useIntl();

  return (
    <>
      <Seo title="404: Not found" />
      <Layout>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} className={text_padding}>
            <Typography className={text}>{Intl.formatMessage({id: "text404_1"})}</Typography>
            <Typography className={text}>{Intl.formatMessage({id: "text404_2"})}</Typography>
          </Grid>
          <Grid item xs={10}>
            <CardMedia
              component="img"
              image={errImg}
              alt='404' />
          </Grid>
          <Grid item xs={12}>
            <Button className={button} onClick={() => navigate('/')} type={"primary"}>{Intl.formatMessage({id: "btn_404"})}</Button>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

export default Page404